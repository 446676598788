import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useMutation } from "@tanstack/react-query";
import { toast } from "@/components/ui/use-toast";
import { AxiosError } from "axios";
import { Form } from "@/components/ui/form";
import { INewLocation } from "@/types/types";
import { addNewLocation } from "@/api/location";
import Name from "./Name";
import Map from "./Map";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { queryClient } from "@/lib/react-query";

export const LocationSchema = z.object({
  title: z
    .string({
      required_error: "يجب ادخال عنوان الموقع",
    })
    .min(2, { message: "الحد الأدنى للإسم هو حرفين" })
    .max(30, { message: "الحد الأقصى للإسم هو 30 حرف" }),
  address: z
    .string({ required_error: "يجب ادخال عنوان الموقع" })
    .min(5, { message: "يجب ادخال عنوان الموقع" })
    .max(100, { message: "يجب ادخال عنوان الموقع" }),
  lng: z
    .number({ required_error: "يجب ادخال عنوان الموقع" })
    .min(-180, { message: "يجب ادخال عنوان الموقع" })
    .max(180, { message: "يجب ادخال عنوان الموقع" }),
  lat: z
    .number({ required_error: "يجب ادخال عنوان الموقع" })
    .min(-90, { message: "يجب ادخال عنوان الموقع" })
    .max(90, { message: "يجب ادخال عنوان الموقع" }),
});

const LocationForm = () => {
  const navigator = useNavigate();
  const { mutate, isPending } = useMutation({
    mutationKey: ["newLocation"],
    mutationFn: (data: INewLocation) => addNewLocation(data),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["locations"] });
      toast({
        title: "تم إضافة الموقع بنجاح",
      });

      form.reset();
      navigator("/locations");
    },
    onError: (error: AxiosError) => {
      toast({
        title: "حدث خطأ أثناء إضافة الموقع",
        description: error.message,
        variant: "destructive",
      });
    },
  });

  const form = useForm<z.infer<typeof LocationSchema>>({
    resolver: zodResolver(LocationSchema),
    defaultValues: {
      title: "",
      address: "",
      lng: 0,
      lat: 0,
    },
  });

  useEffect(() => {
    // on error of the form
    if (form.formState.errors) {
      const errorString = Object.values(form.formState.errors)
        .map((error) => error.message)
        .join(", ");

      if (errorString) {
        toast({
          title: "حدث خطأ أثناء إضافة الموقع",
          description: errorString,
          variant: "destructive",
        });
      }
    }
  }, [form.formState.errors]);

  function onSubmit(data: z.infer<typeof LocationSchema>) {
    mutate(data);
  }

  return (
    <div className="container flex  flex-1 items-center justify-center">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="w-full">
          <h1 className="text-2xl  font-bold text-primary">عنوان جديد</h1>
          <div className="mt-8 space-y-4">
            <Name />
            <Map />
            <Input disabled value={form.watch("address")} />
            <Button isLoading={isPending} type="submit" className="w-full">
              إضافة
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default LocationForm;
