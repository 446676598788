import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <div className="container flex max-w-[40rem] flex-col items-center justify-center">
      <img src="/404.svg" alt="" />
      <h1 className="mb-4 text-center text-2xl font-bold text-primary">
        404 - لم يتم العثور على الصفحة المطلوبة
      </h1>

      <Button>
        <Link to="/">الصفحة الرئيسية </Link>
      </Button>
    </div>
  );
};

export default Error;
