import React, { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axiosInstance from "@/lib/axios";
import usePackageStore from "@/store/usePackageStore";
import { IPayment, Window } from "@/types/types";

const BuyPackage: React.FC = () => {
  const { carId, packageId, packagePrice } = usePackageStore();
  const navigate = useNavigate();
  const [cookies] = useCookies(["jwt"]);

  const handlePaymentCompletion = useCallback(
    async (payment: IPayment): Promise<void> => {
      return new Promise((resolve, reject) => {
        if (!packageId) {
          reject(new Error("Missing package ID"));
          return;
        }

        if (payment.status === "initiated" || payment.status === "paid") {
          axiosInstance
            .post("/package/buy", {
              paymentId: payment.id,
            })
            .then((response) => {
              if (response.status === 201) {
                resolve();
              } else {
                reject(new Error(`Unexpected status code: ${response.status}`));
              }
            })
            .catch((error) => {
              console.error("Error processing package purchase: ", error);
              reject(error);
            });
        } else {
          reject(new Error(`Invalid payment status: ${payment.status}`));
        }
      });
    },
    [carId, packageId, navigate],
  );
  const initializeMoyasar = useCallback(() => {
    const windows = window as Window;
    if (!windows?.Moyasar) return;

    try {
      windows.Moyasar.init({
        element: ".mysr-form",
        amount: 100 * packagePrice,
        currency: "SAR",
        description: "Bluewash Package Purchase",
        publishable_api_key: import.meta.env.VITE_MOYASSER_API_KEY,
        callback_url: `${import.meta.env.MODE === "development" ? "http://localhost:5173/packages/my-packages" : `${import.meta.env.VITE_HOST_URL}/packages/my-packages`}`,
        methods: ["creditcard", "applepay", "stcpay"],
        metadata: {
          type: "package",
          packageId: packageId,
          carId: carId,
          token: cookies.jwt,
        },
        apple_pay: {
          country: "SA",
          label: "Bluewash Package",
          validate_merchant_url: "https://api.moyasar.com/v1/applepay/initiate",
        },
        on_completed: handlePaymentCompletion,
      });
    } catch (error) {
      console.error("Error initializing Moyasar:", error);
      // Handle error (e.g., show error message to user)
    }
  }, [carId, packageId, cookies.jwt, handlePaymentCompletion]);

  useEffect(() => {
    if (!packageId) {
      navigate("/packages");
      return;
    }

    initializeMoyasar();
  }, [carId, packageId, navigate, initializeMoyasar]);

  if (!packageId) {
    console.log("Missing package data:", { carId, packageId });
    navigate("/packages");
    return null;
  }

  return (
    <div className="container p-8">
      <h1 className="mb-6 text-2xl font-bold text-primary">شراء الباقة</h1>
      <div className="mysr-form"></div>
    </div>
  );
};

export default BuyPackage;
