import React, { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useMutation } from "@tanstack/react-query";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "@/components/ui/input-otp";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { toast } from "@/components/ui/use-toast";
import { loginVerifyOTP, registerVerifyOTP } from "@/api/auth";

const OTP_LENGTH = 4;

const LoginSchema = z.object({
  otp: z
    .string()
    .length(OTP_LENGTH, {
      message: `رمز التحقق يجب أن يكون ${OTP_LENGTH} أرقام.`,
    })
    .regex(/^\d+$/, {
      message: `رمز التحقق يجب أن يكون ${OTP_LENGTH} أرقام.`,
    }),
});

type LoginSchemaType = z.infer<typeof LoginSchema>;

interface ModalProps {
  isOTPSent: boolean;
  setIsOTPSent: (value: boolean) => void;
  phone: string;
  type: "login" | "register";
  name?: string;
}

const Modal: React.FC<ModalProps> = ({
  isOTPSent,
  setIsOTPSent,
  phone,
  name,
  type,
}) => {
  const navigate = useNavigate();
  const [, setCookie] = useCookies(["jwt"]);

  const form = useForm<LoginSchemaType>({
    resolver: zodResolver(LoginSchema),
    defaultValues: {
      otp: "",
    },
  });

  const { mutate, isPending } = useMutation({
    mutationKey: ["verify-otp"],
    mutationFn: (data: { phone: string; OTP: string; name?: string }) =>
      type === "login" ? loginVerifyOTP(data) : registerVerifyOTP(data),
    onSuccess: (data) => {
      setIsOTPSent(false);
      setCookie("jwt", data?.token, { path: "/" });
      toast({
        title: "تم تسجيل الدخول",
        description: data?.message || "تم تسجيل الدخول",
      });
      navigate("/");
    },
    onError: (error: Error) => {
      toast({
        title: "حدث خطأ",
        description: error.message,
        variant: "destructive",
      });
    },
  });

  const onSubmit = useCallback(
    (data: LoginSchemaType) => {
      mutate({ phone, OTP: data.otp, ...(name && { name }) });
    },
    [mutate, phone, name],
  );

  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      if (name === "otp" && value.otp?.length === OTP_LENGTH) {
        form.handleSubmit(onSubmit)();
      }
    });
    return () => subscription.unsubscribe();
  }, [form, onSubmit]);

  const handleResend = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    // TODO: Implement resend logic
    console.log("Resend OTP");
  }, []);

  return (
    <Dialog onOpenChange={setIsOTPSent} open={isOTPSent}>
      <DialogContent onPointerDownOutside={(e) => e.preventDefault()}>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="m-auto flex w-full flex-col gap-y-4"
          >
            <DialogHeader className="gap-y-4">
              <DialogTitle className="text-center">
                أدخل رمز التحقق المرسل إلى رقم جوالك
              </DialogTitle>
              <DialogDescription asChild>
                <div className="flex justify-center text-right">
                  <FormField
                    control={form.control}
                    name="otp"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>رمز التحقق </FormLabel>
                        <FormControl>
                          <InputOTP maxLength={OTP_LENGTH} {...field}>
                            <InputOTPGroup>
                              {[...Array(OTP_LENGTH)].map((_, index) => (
                                <InputOTPSlot key={index} index={index} />
                              ))}
                            </InputOTPGroup>
                          </InputOTP>
                        </FormControl>
                        <FormDescription>
                          الرجاء إدخال الرمز المرسل إلى رقم جوالك
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </DialogDescription>
            </DialogHeader>
            <DialogFooter className="m-auto">
              <div className="flex items-center justify-center gap-4">
                <Button
                  className="w-24"
                  variant="secondary"
                  onClick={handleResend}
                  disabled
                >
                  إعادة إرسال
                </Button>
                <Button isLoading={isPending} className="w-24" type="submit">
                  تأكيد
                </Button>
              </div>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
