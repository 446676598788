// Updated MyPackages.tsx
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getMyPackages } from "@/api/packages";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { differenceInDays, format } from "date-fns";
import { ar } from "date-fns/locale";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import {
  InfoIcon,
  Package,
  Loader2,
  Car,
  Calendar,
  ChevronDown,
  ChevronUp,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Separator } from "@/components/ui/separator";
import { IMyPackagePurchase } from "@/types/types";
import { useNavigate } from "react-router-dom";
import { usePackageBookStore } from "@/store/usePackageBookStore";

const MyPackages = () => {
  const navigate = useNavigate();
  const setPackageBooking = usePackageBookStore(
    (state) => state.setPackageBooking,
  );
  const [selectedPackage, setSelectedPackage] =
    React.useState<IMyPackagePurchase | null>(null);
  const [showInactive, setShowInactive] = React.useState(false);

  const { data: myPackages, isLoading } = useQuery({
    queryKey: ["my-packages"],
    queryFn: getMyPackages,
  });

  const handleServiceSelect = (
    packagePurchaseId: string,
    serviceId: string,
  ) => {
    // Find the package purchase and service usage
    const purchase = myPackages?.find((p) => p.id === packagePurchaseId);
    if (!purchase) return;

    const serviceUsage = purchase.ServiceUsage.find(
      (s) => s.serviceId === serviceId,
    );
    if (!serviceUsage) return;

    const remainingQuantity =
      serviceUsage.totalQuantity - serviceUsage.usedQuantity;

    setPackageBooking({
      purchaseId: packagePurchaseId,
      packageId: purchase.id,
      serviceId,
      remainingQuantity,
      carId: purchase.carId ?? undefined,
    });
    // Navigate to the package booking page
    navigate("/book/by-package");
  };

  if (isLoading) {
    return (
      <div className="flex h-[60vh] items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  if (!myPackages || myPackages.length === 0) {
    return (
      <div className="container mx-auto px-4">
        <div className="flex h-[60vh] items-center justify-center">
          <Card className="w-full max-w-md text-center">
            <CardContent className="pt-6">
              <Package className="mx-auto mb-4 h-12 w-12 text-muted-foreground" />
              <p className="text-lg font-medium text-muted-foreground">
                لا توجد باقات مشتراة
              </p>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }

  // Separate and sort packages
  const { activePackages, inactivePackages } = myPackages.reduce<{
    activePackages: IMyPackagePurchase[];
    inactivePackages: IMyPackagePurchase[];
  }>(
    (acc, purchase) => {
      const daysLeft = differenceInDays(
        new Date(purchase.expiresAt),
        new Date(),
      );
      const isExpired = daysLeft <= 0;
      const allServicesUsed = purchase.ServiceUsage.every(
        (service) => service.usedQuantity >= service.totalQuantity,
      );
      const isUnavailable = isExpired || allServicesUsed;

      if (isUnavailable) {
        acc.inactivePackages.push(purchase);
      } else {
        acc.activePackages.push(purchase);
      }
      return acc;
    },
    { activePackages: [], inactivePackages: [] },
  );

  const renderPackage = (purchase: IMyPackagePurchase) => {
    const daysLeft = differenceInDays(new Date(purchase.expiresAt), new Date());
    const isExpired = daysLeft <= 0;
    const allServicesUsed = purchase.ServiceUsage.every(
      (service) => service.usedQuantity >= service.totalQuantity,
    );
    const isUnavailable = isExpired || allServicesUsed;

    return (
      <Card
        key={purchase.id}
        className={`relative overflow-hidden transition-all duration-200 hover:shadow-md ${
          isUnavailable ? "opacity-60" : ""
        }`}
      >
        <CardHeader className="border-b bg-muted/10 p-4">
          <div className="flex items-start justify-between">
            <div className="flex items-center gap-3">
              <div className="rounded-lg bg-primary/10 p-2">
                <Package className="h-6 w-6 text-primary" />
              </div>
              <div>
                <h2 className="mb-1 font-bold text-primary">
                  {purchase.package.name}
                </h2>
                <p className="text-sm font-medium text-primary">
                  {purchase.payment.amount_format}
                </p>
              </div>
            </div>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-8 w-8"
                  onClick={() => setSelectedPackage(purchase)}
                >
                  <InfoIcon className="h-4 w-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>عرض تفاصيل الباقة</TooltipContent>
            </Tooltip>
          </div>
        </CardHeader>

        <CardContent className="p-4">
          <div className="mb-4 flex items-center gap-2 rounded-md bg-muted/30 px-3 py-2">
            <Calendar className="h-4 w-4 text-muted-foreground" />
            <p
              className={`text-sm font-medium ${
                isUnavailable ? "text-destructive" : "text-muted-foreground"
              }`}
            >
              {allServicesUsed
                ? "تم استخدام جميع الخدمات"
                : `متبقي ${Math.max(0, daysLeft)} يوم`}
            </p>
          </div>

          <div className="space-y-4">
            {purchase.ServiceUsage.map((serviceUsage) => {
              const usagePercentage =
                (serviceUsage.usedQuantity / serviceUsage.totalQuantity) * 100;
              const isFullyUsed =
                serviceUsage.usedQuantity >= serviceUsage.totalQuantity;
              const isAvailable = !isFullyUsed && !isUnavailable;

              return (
                <div
                  key={serviceUsage.serviceId}
                  className={`rounded-lg border bg-card p-3 ${
                    !isAvailable ? "opacity-50" : ""
                  }`}
                >
                  <div className="mb-3 flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <Car className="h-4 w-4 text-primary" />
                      <span className="font-medium">
                        {serviceUsage.service.name}
                      </span>
                    </div>
                    <span className="rounded-full bg-primary/10 px-2 py-1 text-xs font-medium text-primary">
                      {serviceUsage.usedQuantity}/{serviceUsage.totalQuantity}
                    </span>
                  </div>
                  <div className="h-2 w-full overflow-hidden rounded-full bg-secondary">
                    <div
                      className="h-full rounded-full bg-primary transition-all duration-500"
                      style={{ width: `${usagePercentage}%` }}
                    />
                  </div>
                  {isAvailable && (
                    <Button
                      className="mt-3 w-full"
                      size="sm"
                      onClick={() =>
                        handleServiceSelect(purchase.id, serviceUsage.serviceId)
                        
                      }
                    >
                      احجز الآن
                    </Button>
                  )}
                </div>
              );
            })}
          </div>
        </CardContent>
      </Card>
    );
  };

  return (
    <TooltipProvider>
      <div className="container mx-auto min-h-screen px-4 pb-8 pt-6">
        <div className="mb-8 flex items-center justify-between">
          <h1 className="text-2xl font-bold text-primary">باقاتي</h1>
          <span className="text-sm text-muted-foreground">
            {myPackages.length} باقات
          </span>
        </div>

        <div className="space-y-6">
          {/* Active Packages */}
          {activePackages.length > 0 && (
            <div className="space-y-4">
              <h2 className="text-lg font-semibold text-primary">
                الباقات النشطة
              </h2>
              <div className="grid gap-6 sm:grid-cols-1 lg:grid-cols-2">
                {activePackages.map(renderPackage)}
              </div>
            </div>
          )}

          {/* Inactive Packages Toggle Button */}
          {inactivePackages.length > 0 && (
            <>
              <Separator className="my-8 h-2 rounded-full bg-primary" />
              <div className="flex flex-col items-center space-y-4">
                <Button
                  variant="outline"
                  className="w-full max-w-md gap-2"
                  onClick={() => setShowInactive(!showInactive)}
                >
                  {showInactive ? "إخفاء" : "عرض"} الباقات المنتهية (
                  {inactivePackages.length})
                  {showInactive ? (
                    <ChevronUp className="h-4 w-4" />
                  ) : (
                    <ChevronDown className="h-4 w-4" />
                  )}
                </Button>
              </div>
            </>
          )}

          {/* Inactive Packages */}
          {inactivePackages.length > 0 && showInactive && (
            <div className="space-y-4 pt-4">
              <div className="grid gap-6 sm:grid-cols-1 lg:grid-cols-2">
                {inactivePackages.map(renderPackage)}
              </div>
            </div>
          )}
        </div>
      </div>

      <Dialog
        open={!!selectedPackage}
        onOpenChange={() => setSelectedPackage(null)}
      >
        <DialogContent dir="rtl" className="sm:max-w-md">
          <DialogHeader className="space-y-3 text-right">
            <DialogTitle className="text-xl">
              {selectedPackage?.package.name}
            </DialogTitle>
            <DialogDescription className="text-base">
              {selectedPackage?.package.description}
            </DialogDescription>
          </DialogHeader>

          <Card className="border-2">
            <CardContent className="grid gap-4 p-4 text-sm">
              <div className="flex items-center justify-between rounded-lg bg-muted p-3">
                <span className="text-muted-foreground">تاريخ الشراء</span>
                <span className="font-medium">
                  {selectedPackage &&
                    format(
                      new Date(selectedPackage.payment.createdAt),
                      "dd/MM/yyyy",
                      {
                        locale: ar,
                      },
                    )}
                </span>
              </div>

              <div className="flex items-center justify-between rounded-lg bg-muted p-3">
                <span className="text-muted-foreground">تاريخ الانتهاء</span>
                <span className="font-medium">
                  {selectedPackage &&
                    format(new Date(selectedPackage.expiresAt), "dd/MM/yyyy", {
                      locale: ar,
                    })}
                </span>
              </div>

              <div className="flex items-center justify-between rounded-lg bg-primary/10 p-3">
                <span className="font-medium">قيمة الباقة</span>
                <span className="font-bold text-primary">
                  {selectedPackage?.payment.amount_format}
                </span>
              </div>
            </CardContent>
          </Card>

          <DialogFooter className="mt-2 sm:justify-start">
            <Button
              type="button"
              variant="secondary"
              onClick={() => setSelectedPackage(null)}
            >
              إغلاق
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </TooltipProvider>
  );
};

export default MyPackages;
