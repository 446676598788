import { Link, useLocation, useNavigate } from "react-router-dom";
import ProtectedRoutes from "../ProtectedRoutes";
import { Button } from "../ui/button";

interface AuthLayoutProps {
  children: React.ReactNode;
}

const MainLayout = ({ children }: AuthLayoutProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  return (
    <ProtectedRoutes>
      <div className="flex h-screen  w-screen flex-col ">
        <Link to="/" className="my-4 bg-gray-50 shadow">
          <img src="/Logo/blue.png" className="m-auto w-48 " alt="" />
        </Link>
        {pathname !== "/" && (
          <div className="container">
            <Button
              variant="link"
              onClick={() => {
                navigate("/");
              }}
            >
              الرجوع
            </Button>
          </div>
        )}
        {children}
      </div>
    </ProtectedRoutes>
  );
};

export default MainLayout;
