"use client";

import { INewCar } from "@/types/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import { useMutation } from "@tanstack/react-query";
import { newCar } from "@/api/car";
import { toast } from "@/components/ui/use-toast";
import { queryClient } from "@/lib/react-query";

import { useNavigate } from "react-router-dom";
import ManufactureCar from "@/components/Car/EditCar/ManufactureCar";
import ColorCar from "@/components/Car/EditCar/ColorCar";
import ModeleCar from "@/components/Car/EditCar/ModelCar";
import PlateCar from "@/components/Car/EditCar/PlateCar";
import { Button } from "@/components/ui/button";

export const carSchema = z.object({
  manufacture: z
    .string({ required_error: "يجب إدخال الشركة المصنعة" })
    .min(2)
    .max(30),
  model: z.string({ required_error: "يجب إدخال نوع السيارة" }).max(30),
  plateNumber: z
    .string({ required_error: "يجب إدخال رقم اللوحة" })
    .max(4, { message: "يجب أن لا يزيد رقم اللوحة عن 4 أرقام" })
    .optional(),
  color: z
    .string({
      required_error: "يجب إدخال لون السيارة",
    })
    .min(3)
    .max(10),
});
const NewCar = () => {
  const navigator = useNavigate();
  const { mutate, isPending } = useMutation({
    mutationKey: ["newCar"],
    mutationFn: (data: INewCar) => newCar(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["new-car"] });
      toast({
        title: "تم تعديل السيارة بنجاح",
      });
      navigator("/cars");
    },
    onError: (error) => {
      toast({
        title: "حدث خطأ ما",
        description: error.message,
        variant: "destructive",
      });
    },
  });

  const form = useForm<z.infer<typeof carSchema>>({
    resolver: zodResolver(carSchema),
    defaultValues: {
      color: "",
      manufacture: "",
      model: "",
      plateNumber: "",
    },
  });

  // 2. Define a submit handler.
  function onSubmit(values: z.infer<typeof carSchema>) {
    console.log(values);
    mutate(values);
  }
  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="container space-y-8 text-right"
      >
        <h1 className="text-2xl  font-bold text-primary">أضف سيارة</h1>
        <ManufactureCar />
        <ModeleCar />
        <ColorCar />
        <PlateCar />
        <Button isLoading={isPending} type="submit">
          أضف
        </Button>
      </form>
    </FormProvider>
  );
};

export default NewCar;
