import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface PackageBookState {
  packageId: string | null;
  serviceId: string | null;
  carId: string | null;
  purchaseId: string | null;
  remainingQuantity: number | null;
  setPackageBooking: (params: {
    purchaseId: string;
    packageId: string;
    serviceId: string;
    remainingQuantity: number;
    carId?: string;
  }) => void;
  resetPackageBooking: () => void;
}

export const usePackageBookStore = create<PackageBookState>()(
  devtools(
    persist(
      (set) => ({
        packageId: null,
        serviceId: null,
        carId: null,
        purchaseId: null,
        remainingQuantity: null,
        setPackageBooking: ({
          purchaseId,
          packageId,
          serviceId,
          remainingQuantity,
          carId,
        }) =>
          set({
            purchaseId,
            packageId,
            serviceId,
            remainingQuantity,
            carId: carId || null,
          }),
        resetPackageBooking: () =>
          set({
            packageId: null,
            serviceId: null,
            carId: null,
            purchaseId: null,
            remainingQuantity: null,
          }),
      }),
      {
        name: "package-booking-storage",
      },
    ),
  ),
);
