import Banner from "../Auth/Banner";
import UnprotectedRoutes from "../UnprotectedRoutes";

interface AuthLayoutProps {
  children: React.ReactNode;
}

const AuthLayout = ({ children }: AuthLayoutProps) => {
  return (
    <UnprotectedRoutes>
      <div className="flex h-screen w-screen flex-col lg:grid lg:grid-cols-2 lg:gap-0 ">
        <div>{children}</div>
        <Banner />
      </div>
    </UnprotectedRoutes>
  );
};

export default AuthLayout;
