import { handleZodError } from "@/helpers/zod-error-handler";
import axiosInstance from "../lib/axios";
import { ITime } from "@/types/types";
import dayjs from "dayjs";

export const getAllTimesByService = async (data: ITime) => {
  try {
    if (!data.serviceId || !data.date)
      throw new Error("Service ID and Date are required");

    const { date, ...rest } = data;
    const response = await axiosInstance.get<string[]>(
      "/shift/available-times",
      {
        params: {
          ...rest,
          date: dayjs(date).format("YYYY-MM-DD"),
        },
      },
    );

    return response.data;
  } catch (error) {
    handleZodError(error);
  }
};
