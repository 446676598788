import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import Icon from "@/components/ui/icon";
import ReactHtmlParser from "react-html-parser";

const ServiceDetailsDialog = ({
  description,
  name,
}: {
  description: string | null;
  name: string;
}) => {
  return (
    <AlertDialog>
      <AlertDialogTrigger>
        <Icon name="info" className="cursor-pointer fill-primary text-white" />
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle className="text-right text-primary underline">
            {name}
          </AlertDialogTitle>
          <AlertDialogDescription asChild dir="rtl" className="text-justify">
            <div>{ReactHtmlParser(description || "")} </div>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>إخفاء</AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ServiceDetailsDialog;
