import useCartStore from "@/store/useCartStore";

const Summary = () => {
  const { servicePrice, totalAfterDiscount } = useCartStore((state) => state);

  return (
    <div className="border p-4">
      <h1 className="font-bold">ملخص الطلب:</h1>
      <div className="flex justify-between">
        <h6>السعر:</h6>
        <h6>{servicePrice ? servicePrice : 0} ر.س</h6>
      </div>
      <div className="flex justify-between">
        <h6>الخصم:</h6>
        <h6>
          {totalAfterDiscount && totalAfterDiscount > 0
            ? servicePrice && servicePrice - totalAfterDiscount
            : 0}
          ر.س
        </h6>
      </div>
      <div className="flex justify-between">
        <h6>الإجمالي بعد الخصم: </h6>
        <h6>
          {totalAfterDiscount && totalAfterDiscount > 0
            ? totalAfterDiscount
            : servicePrice || 0}
          ر.س
        </h6>
      </div>
    </div>
  );
};

export default Summary;
