"use client";

import { bookSchema } from "@/pages/bookings/book";
import { useFormContext } from "react-hook-form";
import { z } from "zod";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../ui/form";
import { getAllCars } from "@/api/car";
import { useQuery } from "@tanstack/react-query";
import useCartStore from "@/store/useCartStore";

const Car = () => {
  const form = useFormContext<z.infer<typeof bookSchema>>();
  const { setCardId } = useCartStore((state) => state);
  const { data, isLoading } = useQuery({
    queryKey: ["cars"],
    queryFn: getAllCars,
  });

  return (
    <>
      <FormField
        control={form.control}
        name="carId"
        render={({ field }) => (
          <FormItem>
            <FormLabel>رقم السيارة</FormLabel>
            <FormControl>
              <select
                dir="rtl"
                disabled={isLoading}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  setCardId(e.target.value);
                }}
                value={field.value}
                className="h-12 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
              >
                <option value="">
                  {isLoading ? "جاري التحميل" : "اختر السيارة"}
                </option>
                {data?.map((car, index) => (
                  <option key={index} value={car.id}>
                    {car.manufacture} - {car.model} - {car.plateNumber}
                  </option>
                ))}
              </select>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  );
};

export default Car;
