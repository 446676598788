import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

import { useMutation } from "@tanstack/react-query";
import { deleteCar } from "@/api/car";
import { Button } from "@/components/ui/button";
import Icon from "@/components/ui/icon";
import { queryClient } from "@/lib/react-query";
import { toast } from "@/components/ui/use-toast";
import React from "react";

const DeleteCar = ({ carId }: { carId: string }) => {
  const [open, setOpen] = React.useState(false);
  const { mutate, isPending } = useMutation({
    mutationKey: ["delete-car"],
    mutationFn: deleteCar,
    onSuccess: () => {
      console.log(open);
      setOpen(false);
      console.log(open);
      queryClient.invalidateQueries({ queryKey: ["cars"] });
      toast({
        title: "تم حذف السيارة بنجاح",
      });
    },
    onError: (error) => {
      toast({
        title: "حدث خطأ ما",
        description: error.message,
        variant: "destructive",
      });
    },
  });

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <Button variant={"destructive"} size="icon">
          <Icon name="trash" size={20} />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogDescription asChild className="">
            <div>
              <DialogTitle className="text-center">
                هل أنت متأكد من حذف السيارة؟
              </DialogTitle>

              <div className="mt-8 flex justify-start gap-4">
                <Button
                  variant="destructive"
                  size="lg"
                  onClick={() => {
                    mutate(carId);
                  }}
                  disabled={isPending}
                  isLoading={isPending}
                >
                  حذف
                </Button>
                <Button
                  size="lg"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  لا
                </Button>
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteCar;
