import {
  FormField,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useFormContext } from "react-hook-form";
import { z } from "zod";
import { LocationSchema } from "./Form";

const Name = () => {
  const form = useFormContext<z.infer<typeof LocationSchema>>();
  return (
    <FormField
      control={form.control}
      name="title"
      render={({ field }) => (
        <div className="space-y-2">
          <div>
            <FormLabel>اسم العنوان (لن يؤثر على الموقع)</FormLabel>
            <div className="flex flex-row-reverse gap-x-4 ">
              <FormControl>
                <Input required {...field} placeholder="الاسم*" />
              </FormControl>
            </div>
          </div>

          <FormMessage />
        </div>
      )}
    />
  );
};

export default Name;
