// import Sliders from "@/components/Home/Sliders";
import Icon from "@/components/ui/icon";
import { useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";

function App() {
  const [, , removeCookie] = useCookies(["jwt"]);
  const navigate = useNavigate();
  const ROUTES = [
    {
      path: "/bookings/book",
      name: "احجز الآن",
      component: "calendar-plus" as const,
    },
    {
      path: "/bookings",
      name: "حجوزاتي",
      component: "calendar" as const,
    },
    {
      path: "/cars",
      name: "سياراتي",
      component: "car" as const,
    },
    {
      path: "/locations",
      name: "عناويني",
      component: "map-pinned" as const,
    },
    {
      path: "/packages",
      name: "الباقات",
      component: "file-stack" as const,
    },
    {
      path: "packages/my-packages",
      name: "باقاتي",
      component: "files" as const,
    },
    {
      path: "/about",
      name: "عن بلو",
      component: "circle-help" as const,
    },
    {
      path: "/help",
      name: "الدعم الفني",
      component: "phone" as const,
    },
    {
      name: "تسجيل الخروج",
      component: "log-out" as const,
      onClick: async () => {
        await removeCookie("jwt");
        navigate("/auth/login");
      },
    },
  ];

  return (
    <div className="container my-4 flex w-full flex-col  gap-8 ">
      {/* <Sliders /> */}
      <div className="flex justify-between rounded-lg bg-primary p-2 text-xl font-bold text-white">
        <h1>حياك الله </h1>
        <h1>{new Date().toLocaleDateString()}</h1>
      </div>
      <div className="mb-8 flex flex-wrap items-center justify-center gap-4">
        {ROUTES.map((route) =>
          route.path ? (
            <Link
              to={route.path}
              key={route.path}
              className="flex size-36 cursor-pointer flex-col items-center justify-center gap-4 rounded-lg border-2 p-4 text-primary transition-all duration-300 ease-in-out hover:bg-primary hover:text-white"
            >
              <Icon name={route.component} size={40} />
              <h1>{route.name}</h1>
            </Link>
          ) : (
            <div
              key={route.name}
              onClick={route.onClick}
              className="flex size-36 cursor-pointer flex-col items-center justify-center gap-4 rounded-lg border-2 p-4 text-primary transition-all duration-300 ease-in-out hover:bg-primary hover:text-white"
            >
              <Icon name={route.component} size={40} />
              <h1>{route.name}</h1>
            </div>
          ),
        )}
      </div>
    </div>
  );
}

export default App;
