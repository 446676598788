import { getAllCars } from "@/api/car";
import DeleteCar from "@/components/Car/EditCar/DeleteCar";
import EditCar from "@/components/Car/EditCar/EditCar";
import Loading from "@/components/ui/Loading";
import { Button } from "@/components/ui/button";
import Icon from "@/components/ui/icon";
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/utils";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";

const Cars = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["cars"],
    queryFn: getAllCars,
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="container">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl  font-bold text-primary">سياراتي</h1>

        <Button className="bg-primary text-white" size="sm">
          <Link to="/cars/new">أضف سيارة</Link>
        </Button>
      </div>
      <div>
        <div className="mt-8 grid grid-cols-1 gap-4">
          {data?.map((car) => (
            <div
              key={car.id}
              className="flex items-center justify-between rounded-lg bg-white p-4 shadow-md"
            >
              <div className="flex items-center gap-4">
                <div className="flex">
                  <Icon name="car" className="text-primary" size={40} />
                  <Separator orientation="vertical" className="mr-4 h-12" />
                </div>
                <div>
                  <h1 className="text-lg font-bold text-primary">
                    {car.manufacture} - {car.model}
                  </h1>
                  <p className="flex items-center gap-2 text-sm font-medium">
                    <span className="font-bold">اللون:</span>
                    <span
                      style={{ backgroundColor: car.color }}
                      className={cn(
                        "flex size-4 cursor-pointer items-center justify-center rounded-md border border-gray-300 ",
                      )}
                    ></span>
                  </p>
                  <p className="text-sm font-medium">
                    <span className="font-bold">اللوحة:</span> {car.plateNumber}
                  </p>
                </div>
              </div>
              <div className="flex items-center justify-center gap-x-2">
                <EditCar car={car} />
                <DeleteCar carId={car.id} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Cars;
