import { handleZodError } from "@/helpers/zod-error-handler";
import axiosInstance from "../lib/axios";
import { ICars, IEditCar, INewCar } from "@/types/types";

export const getAllCars = async () => {
  try {
    const response = await axiosInstance.get<ICars[]>("/car");

    return response.data;
  } catch (error) {
    handleZodError(error);
  }
};

export const editCar = async (data: IEditCar) => {
  try {
    const { id, plateNumber: plateNumber, ...rest } = data;
    const response = await axiosInstance.put(`/car/${id}`, {
      ...rest,
      plateNumber,
    });

    return response.data;
  } catch (error) {
    handleZodError(error);
  }
};

export const newCar = async (data: INewCar) => {
  try {
    const response = await axiosInstance.post(`/car`, {
      color: data.color,
      manufacture: data.manufacture,
      model: data.model,
      plateNumber: data.plateNumber,
    });

    return response.data;
  } catch (error) {
    handleZodError(error);
  }
};

export const deleteCar = async (id: string) => {
  try {
    const response = await axiosInstance.delete(`/car/${id}`);

    return response.data;
  } catch (error) {
    handleZodError(error);
  }
};
