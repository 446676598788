"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import Car from "@/components/Booking/Book/Cars";
import Location from "@/components/Booking/Book/Locations";
import { useNavigate } from "react-router-dom";
import useCartStore from "@/store/useCartStore";
import { useEffect } from "react";
import { Card, CardContent } from "@/components/ui/card";
import { Package } from "lucide-react";
import { usePackageBookStore } from "@/store/usePackageBookStore";
import { useMutation } from "@tanstack/react-query";
import { bookByPackage } from "@/api/packages";
import { ICreateBookingByPackageDto } from "@/types/types";
import { toast } from "@/components/ui/use-toast";
import DateComp from "@/components/Booking/Book/DateCompP";
import TimeComp from "@/components/Booking/Book/TimeCompP";

const today = new Date();
today.setHours(0, 0, 0, 0);
const dayAfterSevenDays = new Date(today);
dayAfterSevenDays.setDate(today.getDate() + 7);

export const packageBookSchema = z.object({
  bookingDate: z.date({ required_error: "يجب اختيار تاريخ الحجز" }).refine(
    (date) => {
      return date >= today && date <= dayAfterSevenDays;
    },
    {
      message: "يجب اختيار تاريخ بين اليوم وبعد 7 أيام",
    },
  ),
  time: z
    .string({ required_error: "يجب اختيار الوقت" })
    .min(5, {
      message: "يجب اختيار الوقت",
    })
    .max(5, {
      message: "يجب اختيار الوقت",
    }),
  serviceId: z.string(),
  locationId: z.string({ required_error: "يجب اختيار الموقع" }),
  carId: z
    .string({ required_error: "يجب اختيار السيارة" })
    .min(20, {
      message: "رقم السيارة يجب ان يكون أكبر من 20 حرف",
    })
    .max(40, {
      message: "رقم السيارة يجب ان يكون أقل من 24 حرف",
    })
    .optional(),
  packageId: z.string(),
});

const PackageBook = () => {
  const navigate = useNavigate();
  const { mutate } = useMutation({
    mutationKey: ["bookByPackage"],
    mutationFn: (data: ICreateBookingByPackageDto) => bookByPackage(data),
    onSuccess: () => {
      resetPackageBooking();
      toast({
        title: "تم حجز الباقة بنجاح",
        description: " تم حجز الباقة بنجاح",
      });
      navigate("/bookings");
    },
    onError: (error) => {
      toast({
        title: "حدث خطأ أثناء حجز الباقة",
        description: error.message,
        variant: "destructive",
      });
    },
  });

  const { date, locationId } = useCartStore((state) => state);
  const {
    packageId,
    serviceId,
    carId,
    purchaseId,
    remainingQuantity,
    resetPackageBooking,
  } = usePackageBookStore();

  // Check if we have the required package booking data
  useEffect(() => {
    if (!packageId || !serviceId || !purchaseId) {
      navigate("/bookings");
    }
  }, [packageId, serviceId, purchaseId, navigate]);

  const form = useForm<z.infer<typeof packageBookSchema>>({
    resolver: zodResolver(packageBookSchema),
    defaultValues: {
      serviceId: serviceId || undefined,
      bookingDate: date || undefined,
      locationId: locationId || undefined,
      packageId: packageId || undefined,
    },
  });

  const onSubmit = async (values: ICreateBookingByPackageDto) => {
    try {
      const [hours, minutes] = values?.time?.split(":") || [];
      const dateTime = new Date(values.bookingDate);
      dateTime.setHours(+hours + 3);
      dateTime.setMinutes(parseInt(minutes));

      // Create submission data without time field
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { time, ...submitData } = values;
      submitData.bookingDate = dateTime;

      // If we have carId from purchase, remove it from submission
      // If we don't have carId from purchase, include the selected carId
      if (carId) {
        delete submitData.carId;
      }

      mutate(submitData);
    } catch (error) {
      console.error("Booking error:", error);
    }
  };

  // Handle if the required data is not available
  if (!packageId || !serviceId || !purchaseId) {
    return null;
  }

  return (
    <Form {...form}>
      <div className="container">
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="mx-auto mt-8 w-full max-w-md space-y-4 rounded-lg p-4 shadow-md"
        >
          {/* Hidden Fields */}
          <input
            type="hidden"
            {...form.register("serviceId")}
            value={serviceId}
          />
          <input
            type="hidden"
            {...form.register("packageId")}
            value={packageId}
          />

          {/* Package Usage Info */}
          <Card className="bg-muted/10">
            <CardContent className="pt-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <Package className="h-5 w-5 text-primary" />
                  <span className="text-sm font-medium">الباقة المتبقية</span>
                </div>
                <span className="text-sm font-bold text-primary">
                  {remainingQuantity} خدمة
                </span>
              </div>
            </CardContent>
          </Card>

          <DateComp />
          <TimeComp />

          {/* Only show Car component if we don't have carId from purchase */}
          {!carId && <Car />}

          <Location />

          <Button
            disabled={
              form.formState.isSubmitting ||
              !form.watch("bookingDate") ||
              (!carId && !form.watch("carId")) || // Only require carId if not in purchase
              !form.watch("locationId")
            }
            isLoading={form.formState.isSubmitting}
            type="submit"
            className="w-full"
          >
            احجز الآن
          </Button>
        </form>
      </div>
    </Form>
  );
};

export default PackageBook;