"use client";

import Loading from "@/components/ui/Loading";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import React, { useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import Icon from "@/components/ui/icon";
import { toast } from "@/components/ui/use-toast";
import { LocationSchema } from "./Form";
import { useFormContext } from "react-hook-form";
import { getGoogleMapAddress } from "@/api/location";
import { z } from "zod";
const API_KEY = "AIzaSyD2lT6cRgEgijt6nOS6eJUB4ErmBZ7sczw";

const center = {
  lat: 24.7136,
  lng: 46.6753,
};

const Map = () => {
  const form = useFormContext<z.infer<typeof LocationSchema>>();
  const [search, setSearch] = useState("");
  const [zoom, setZoom] = useState(10);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: API_KEY,
  });

  const setValuesHandler = async (lat: number, lng: number) => {
    setZoom(15);
    form.setValue("lat", lat);
    form.setValue("lng", lng);
    const { data } = await getGoogleMapAddress(lat, lng);
    form.setValue("address", data.results[0].formatted_address);
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerClassName="w-full transition-all h-96 rounded-lg"
      center={{
        lat: form.getValues("lat") || center.lat,
        lng: form.getValues("lng") || center.lng,
      }}
      zoom={zoom}
      options={{
        disableDefaultUI: true,
        zoomControl: true,
        clickableIcons: false,
        draggableCursor: "pointer",
      }}
      onClick={async (e: google.maps.MapMouseEvent) => {
        if (e.latLng) {
          const lat = e.latLng.lat() as number;
          const lng = e.latLng.lng() as number;
          setValuesHandler(lat, lng);
          try {
            setZoom(15);
          } catch (error) {
            console.log(error);
          }
        }
      }}
    >
      <MarkerF
        position={{
          lat: form.getValues("lat") || center.lat,
          lng: form.getValues("lng") || center.lng,
        }}
      />
      <div className="absolute right-2.5 top-4  rounded-lg  shadow-md">
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="بحث عن موقع"
          type="text"
        />
      </div>

      <Button
        className="absolute bottom-28 right-2.5"
        size="icon"
        type="button"
        onClick={async () => {
          try {
            navigator.geolocation.getCurrentPosition(
              async function (position) {
                setZoom(15);
                setValuesHandler(
                  position.coords.latitude,
                  position.coords.longitude,
                );
              },
              function (error) {
                if (error.code == error.PERMISSION_DENIED)
                  toast({
                    title: "حدث خطأ",
                    description: "يجب تفعيل خاصية الموقع الجغرافي",
                    variant: "destructive",
                  });
              },
            );
          } catch (error) {
            console.log(error);
          }
        }}
      >
        <Icon name="locate-fixed" />
      </Button>
    </GoogleMap>
  ) : (
    <Loading />
  );
};

export default React.memo(Map);
