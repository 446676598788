import { getAllLocations } from "@/api/location";
import DeleteLocation from "@/components/Location/DeleteLocation";
import Loading from "@/components/ui/Loading";
import { Button } from "@/components/ui/button";
import Icon from "@/components/ui/icon";
import { Separator } from "@/components/ui/separator";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";

const Locations = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["locations"],
    queryFn: getAllLocations,
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="container">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl  font-bold text-primary">عناويني</h1>

        <Button className="bg-primary text-white" size="sm">
          <Link to="/locations/new">أضف عنوان</Link>
        </Button>
      </div>
      <div>
        <div className="mt-8 grid grid-cols-1 gap-4">
          {data?.map((location) => (
            <div
              key={location.id}
              className="flex items-center justify-between rounded-lg bg-white p-4 shadow-md"
            >
              <div className="flex items-center gap-4">
                <div className="flex">
                  <Icon name="map-pin" className="text-primary" size={40} />
                  <Separator orientation="vertical" className="mr-4 h-12" />
                </div>
                <div>
                  <h1 className="text-lg font-bold text-primary">
                    {location.title}
                  </h1>
                  <div className="flex items-center gap-2 text-sm font-medium">
                    <Icon name="map-pin" size={20} />
                    {location.address}
                  </div>
                </div>
              </div>
              <div>
                <DeleteLocation locationId={location.id} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Locations;
