import { handleZodError } from "@/helpers/zod-error-handler";
import axiosInstance from "../lib/axios";

export const loginSendOTP = async (data: { phone: string }) => {
  try {
    const phoneWithZero = {
      phone: `0${data.phone}`,
    };
    const response = await axiosInstance.post<{
      message: string;
      token: string;
    }>("/auth/login-send-otp", phoneWithZero);

    return response.data;
  } catch (error) {
    handleZodError(error);
  }
};

export const registerSendOTP = async (data: {
  phone: string;
  name?: string;
}) => {
  try {
    const details = {
      phone: `0${data.phone}`,
      name: data.name,
    };
    const response = await axiosInstance.post<{
      message: string;
      token: string;
    }>("/auth/register-send-otp", details);

    return response.data;
  } catch (error) {
    handleZodError(error);
  }
};

export const loginVerifyOTP = async (data: { phone: string; OTP: string }) => {
  try {
    const phoneWithZero = {
      phone: `0${data.phone}`,
      otp: data.OTP,
    };

    const response = await axiosInstance.post<{
      message: string;
      token: string;
    }>("/auth/login-verify-otp", phoneWithZero);

    return response.data;
  } catch (error) {
    handleZodError(error);
  }
};

export const registerVerifyOTP = async (data: {
  phone: string;
  OTP: string;
  name?: string;
}) => {
  try {
    const phoneWithZero = {
      phone: `0${data.phone}`,
      name: data.name,
      otp: data.OTP,
    };

    const response = await axiosInstance.post<{
      message: string;
      token: string;
    }>("/auth/register-verify-otp", phoneWithZero);
    console.log(response.data);
    return response.data;
  } catch (error) {
    handleZodError(error);
  }
};
