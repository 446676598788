import { getBookings } from "@/api/bookings";
import Loading from "@/components/ui/Loading";
import Status from "@/components/ui/Status";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { renderIcon } from "@/helpers/render-icons";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import "dayjs/locale/ar";
import { IBooking } from "@/types/types";
import { useSearchParams } from "react-router-dom";

const Bookings = () => {
  const [searchParams] = useSearchParams();

  const { data, isLoading } = useQuery({
    queryKey: ["bookings"],
    queryFn: getBookings,
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="container space-y-8 pb-8">
      <Header />
      {searchParams.getAll("status")[0] === "paid" ? (
        <div className="rounded-lg border-l-4 border-green-500 bg-green-100 p-4 text-green-700">
          تم الدفع بنجاح
        </div>
      ) : searchParams.getAll("status").length > 0 ? (
        <div className="rounded-lg border-l-4 border-red-500 bg-red-100 p-4 text-red-700">
          فشل الدفع
        </div>
      ) : null}
      <div className="flex flex-wrap items-center justify-center gap-8">
        {data?.map((booking) => (
          <Card key={booking.id} className="relative w-full max-w-sm shadow-lg">
            <CardContent className="flex  flex-col items-center space-x-4 p-4">
              <div className="flex flex-col items-center justify-between gap-2 ">
                <span>{renderIcon("car")}</span>
                <h1 className="text-lg font-bold text-primary">
                  {booking.service.name}
                </h1>
              </div>
              <Separator className="my-4 h-1" />
              <div className="flex w-full items-center justify-between gap-x-4">
                <Details booking={booking} />
                <div className="flex flex-col items-center">
                  <h4 className="font-bold">مدة الخدمة</h4>
                  <div className=" flex size-20 flex-col items-center justify-center rounded-full border-4 border-primary font-bold ">
                    <span className="text-lg">{booking.service.duration}</span>
                    <span>دقيقة</span>
                  </div>
                </div>
              </div>

              <p className="absolute -top-2 right-0 text-sm font-medium">
                <Status status={booking.status} />
              </p>
            </CardContent>
          </Card>
        ))}
        {data?.length === 0 && (
          <div className="text-center text-lg font-bold text-primary">
            <h1>لا يوجد حجوزات </h1>
            <Button asChild>
              <Link to="/bookings/book">قم بالحجز الآن</Link>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const Header = () => {
  return (
    <div className=" flex items-center justify-between">
      <h1 className="text-2xl  font-bold text-primary">الحجوزات</h1>
      <Button className="bg-primary text-white" size="sm">
        <Link to="/bookings/book">حجز جديد</Link>
      </Button>
    </div>
  );
};

interface DetailsProps {
  booking: IBooking;
}

const Details = ({ booking }: DetailsProps) => {
  return (
    <div className="max-w-48 break-words">
      <p className="text-sm font-medium">
        <span className="font-bold">التكلفة:</span> {booking.totalAmount} ر.س
      </p>
      <p className="text-sm font-medium">
        <span className="font-bold">السيارة: </span>
        <span className="w-24">{booking.car.manufacture}</span>
      </p>
      <p className="text-sm font-medium">
        <span className="font-bold">النوع: </span>
        <span className="w-24">{booking.car.model}</span>
      </p>
      <p className="text-sm font-medium">
        <span className="font-bold">التاريخ: </span>
        {booking?.startTime?.split("T")[0]}
      </p>
      <p className="text-sm font-medium">
        <span className="font-bold">الوقت:</span>{" "}
        {booking?.startTime ? formatTime(booking.startTime) : "N/A"}
      </p>
    </div>
  );
};

const formatTime = (utcTimeString: string) => {
  // Create a date object from the UTC string
  const date = new Date(utcTimeString);

  // Format the time
  return date.toLocaleTimeString("ar-SA", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: "Asia/Riyadh",
  });
};

export default Bookings;
