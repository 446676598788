import { handleZodError } from "@/helpers/zod-error-handler";
import axiosInstance from "../lib/axios";
import { IBook, IBooking, ICouponCheck } from "@/types/types";

export const getBookings = async () => {
  try {
    const response = await axiosInstance.get<IBooking[]>("/booking");

    return response.data;
  } catch (error) {
    handleZodError(error);
  }
};

export const book = async (data: IBook) => {
  try {
    const response = await axiosInstance.post("/booking", data);

    return response.data;
  } catch (error) {
    handleZodError(error);
  }
};

export const checkCoupon = async (data: ICouponCheck) => {
  try {
    const response = await axiosInstance.post<{
      totalAfterDiscount: number;
      coupon: string;
    }>(`/coupon/check`, data);

    return response.data;
  } catch (error) {
    handleZodError(error);
  }
};

export const validatePayment = async (id: string | false) => {
  try {
    if (!id) {
      return false;
    }

    const response = await axiosInstance.get<boolean | undefined>(
      `/checkout/validatePayment/${id}`,
    );

    return response.data as boolean | undefined;
  } catch (error) {
    handleZodError(error);
  }
};
