"use client";

import { useFormContext } from "react-hook-form";
import { z } from "zod";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../ui/form";
import { useQuery } from "@tanstack/react-query";
import { getAllServices } from "@/api/service";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";
import { renderIcon } from "@/helpers/render-icons";
import ServiceDetailsDialog from "./ServiceDetailsDialog";
import { bookSchema } from "@/pages/bookings/book";
import useCartStore from "@/store/useCartStore";
import { useEffect } from "react";

const Services = () => {
  const form = useFormContext<z.infer<typeof bookSchema>>();
  const { data, isLoading } = useQuery({
    queryKey: ["services"],
    queryFn: getAllServices,
  });

  const { setServicePrice, coupon, setServiceId } = useCartStore(
    (state) => state,
  );

  useEffect(() => {
    if (form.watch("serviceId")) {
      const id = form.watch("serviceId");
      const service = data?.find((service) => service.id === id);
      setServicePrice(service?.price ?? null);
      setServiceId(id);
    }
  }, [form.watch("serviceId")]);

  return (
    <FormField
      control={form.control}
      name="serviceId"
      render={({ field }) => (
        <FormItem className=" spa snap-end space-y-3 overflow-auto">
          <FormLabel>اختر الخدمة</FormLabel>
          <FormControl>
            <RadioGroup
              onValueChange={field.onChange}
              defaultValue={field.value}
              className="flex w-fit "
            >
              <div className="flex w-fit gap-4 overflow-auto">
                {isLoading ? (
                  <>
                    {Array.from({ length: 5 }).map((_, index) => (
                      <Skeleton
                        key={index}
                        className="mb-4 flex size-16 items-center justify-center rounded-md border"
                      />
                    ))}
                  </>
                ) : (
                  data
                    ?.map((service, index) => (
                      <div key={index} className="flex  flex-col items-center">
                        <FormItem
                          className={cn(
                            "mb-4  flex size-24 items-center justify-center rounded-md border",
                            field.value === service.id &&
                              "bg-primary text-white transition-all duration-300",
                            coupon !== null && "cursor-not-allowed",
                          )}
                        >
                          <FormControl>
                            <RadioGroupItem
                              disabled={coupon !== null}
                              value={service.id}
                              className="hidden"
                            />
                          </FormControl>
                          <FormLabel
                            className={cn(
                              "flex size-24 cursor-pointer flex-col items-center justify-center gap-2 rounded-md",
                              field.value === service.id
                                ? "*:text-white"
                                : "*:text-primary",
                              coupon !== null && "cursor-not-allowed",
                            )}
                          >
                            {service.icon && renderIcon(service.icon)}
                            <span>{service.price} ر.س</span>
                          </FormLabel>
                        </FormItem>
                        <div dir="rtl" className="flex gap-2 *:text-primary">
                          <h6>{service.name}</h6>
                          <ServiceDetailsDialog
                            name={service.name}
                            description={service.description}
                          />
                        </div>
                      </div>
                    ))
                    .reverse()
                )}
              </div>
            </RadioGroup>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default Services;
