import { checkCoupon } from "@/api/bookings";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { toast } from "@/components/ui/use-toast";
import { bookSchema } from "@/pages/bookings/book";
import useCartStore from "@/store/useCartStore";
import { ICouponCheck } from "@/types/types";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useFormContext } from "react-hook-form";
import { z } from "zod";

interface ICouponProps {
  serviceId: string;
  code: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
}

const Coupon = ({ code, serviceId, setCode }: ICouponProps) => {
  const { setCoupon, coupon, setTotalAfterDiscount } = useCartStore(
    (state) => state,
  );
  const form = useFormContext<z.infer<typeof bookSchema>>();
  const { mutate, isPending } = useMutation({
    mutationKey: ["coupon", code, serviceId],
    mutationFn: (data: ICouponCheck) => checkCoupon(data),
    onSuccess: async (data) => {
      setCoupon(data?.coupon || null);
      form.setValue("coupon", data?.coupon);
      setTotalAfterDiscount(data?.totalAfterDiscount || 0);

      toast({
        title: "تم تطبيق الكوبون بنجاح",
      });
    },
    onError: (error: AxiosError) => {
      toast({
        title: "حدث خطأ أثناء التحقق من الكوبون",
        description: error.message,
        variant: "destructive",
      });
    },
  });
  return (
    <div>
      <Label>هل لديك كود خصم؟</Label>
      <div className="flex  h-full items-center justify-center gap-2">
        <Input
          min={2}
          disabled={isPending || coupon !== null}
          value={coupon || code}
          placeholder="كود الخصم"
          onChange={(e) => setCode(e.target.value)}
        />
        <Button
          type="submit"
          disabled={isPending || coupon !== null}
          isLoading={isPending}
          onClick={(e) => {
            e.preventDefault();
            if (!code) {
              toast({
                title: "الكوبون فارغ",
                description: "يجب إدخال كود الكوبون",
              });
              return;
            }

            if (!serviceId) {
              toast({
                title: "خطأ",
                description: "يجب اختيار خدمة لتطبيق الكوبون",
              });
              return;
            }
            mutate({ code, serviceId });
          }}
        >
          تطبيق
        </Button>
        <Button
          type="reset"
          disabled={isPending}
          variant="secondary"
          isLoading={isPending}
          onClick={(e) => {
            e.preventDefault();
            setCode("");
            setCoupon(null);
          }}
        >
          إلغاء
        </Button>
      </div>
      {coupon && (
        <div className="mt-4 font-semibold text-green-600">
          <div>تم تطبيق الخصم {`${coupon}`} على الخدمة</div>
        </div>
      )}
    </div>
  );
};

export default Coupon;
