import { handleZodError } from "@/helpers/zod-error-handler";
import axiosInstance from "../lib/axios";
import { IService } from "@/types/types";

export const getAllServices = async () => {
  try {
    const response = await axiosInstance.get<IService[]>("/service");

    return response.data;
  } catch (error) {
    handleZodError(error);
  }
};
