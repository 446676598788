"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import DateComp from "@/components/Booking/Book/DateComp";
import TimeComp from "@/components/Booking/Book/TimeComp";
import Car from "@/components/Booking/Book/Cars";
import Services from "@/components/Booking/Book/Services";
import Location from "@/components/Booking/Book/Locations";
import { useNavigate } from "react-router-dom";
import Coupon from "@/components/Booking/Book/coupon/coupon";
import { useState } from "react";
import Summary from "@/components/Booking/Book/Summary";
import useCartStore from "@/store/useCartStore";

const today = new Date();
today.setHours(0, 0, 0, 0); // Set time to start of day
const dayAfterSevenDays = new Date(today);
dayAfterSevenDays.setDate(today.getDate() + 7);

export const bookSchema = z.object({
  date: z.date({ required_error: "يجب اختيار تاريخ الحجز" }).refine(
    (date) => {
      return date >= today && date <= dayAfterSevenDays;
    },
    {
      message: "يجب اختيار تاريخ بين اليوم وبعد 7 أيام",
    },
  ),
  time: z
    .string({ required_error: "يجب اختيار الوقت" })
    .min(5, {
      message: "يجب اختيار الوقت",
    })
    .max(5, {
      message: "يجب اختيار الوقت",
    }),
  serviceId: z.string({ required_error: "يجب اختيار الخدمة" }),
  locationId: z.string({ required_error: "يجب اختيار الموقع" }),
  carId: z
    .string({ required_error: "يجب اختيار السيارة" })
    .min(20, {
      message: "رقم السيارة يجب ان يكون أكبر من 20 حرف",
    })
    .max(40, {
      message: "رقم السيارة يجب ان يكون أقل من 24 حرف",
    }),
  coupon: z.string().optional(),
});

const Book = () => {
  const navigator = useNavigate();
  const [code, setCode] = useState("");
  const { serviceId, date, locationId, coupon, carId } = useCartStore(
    (state) => state,
  );

  const form = useForm<z.infer<typeof bookSchema>>({
    resolver: zodResolver(bookSchema),
    defaultValues: {
      serviceId: serviceId || undefined,
      date: date || undefined,
      carId: carId || undefined,
      locationId: locationId || undefined,
      coupon: coupon || undefined,
    },
  });

  async function onSubmit() {
    if (serviceId && date && locationId) {
      console.log("success");
      navigator("/bookings/confirm");
    }
  }

  return (
    <Form {...form}>
      <div className="container">
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className=" mx-auto mt-8 w-full max-w-md space-y-4 rounded-lg p-4 shadow-md"
        >
          <Services />
          <DateComp />
          <TimeComp />
          <Car />
          <Location />
          <Coupon
            code={code}
            setCode={setCode}
            serviceId={form.getValues("serviceId")}
          />
          <Summary />

          <Button
            disabled={
              form.formState.isSubmitting ||
              !form.watch("serviceId") ||
              !form.watch("date") ||
              !form.watch("time") ||
              !form.watch("carId") ||
              !form.watch("locationId")
            }
            isLoading={form.formState.isSubmitting}
            type="submit"
          >
            ادفع الآن
          </Button>
        </form>
      </div>
    </Form>
  );
};

export default Book;
