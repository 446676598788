import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getAllPackages } from "@/api/packages";
import { getAllCars } from "@/api/car";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import Icon from "@/components/ui/icon";
import Loading from "@/components/ui/Loading";
import { ChevronDown } from "lucide-react";
import usePackageStore from "@/store/usePackageStore";
import { useNavigate } from "react-router-dom";

const Packages: React.FC = () => {
  const [selectedCars, setSelectedCars] = useState<Record<string, string>>({});
  const { setCarId, setPackageId, setPackagePrice } = usePackageStore();
  const navigate = useNavigate();

  const { data: packages, isLoading: isLoadingPackages } = useQuery({
    queryKey: ["packages"],
    queryFn: getAllPackages,
  });

  const { data: cars, isLoading: isLoadingCars } = useQuery({
    queryKey: ["cars"],
    queryFn: getAllCars,
  });

  const handlePackageSelection = (packageId: string, oneCar: boolean) => {
    if (oneCar && !selectedCars[packageId]) {
      alert("الرجاء اختيار سيارة أولاً");
      return;
    }
    console.log(
      `الباقة المختارة: ${packageId}, السيارة المختارة: ${selectedCars[packageId]}`,
    );
    setPackageId(packageId);
    setCarId(selectedCars[packageId]);
    setPackagePrice(packages?.find((pkg) => pkg.id === packageId)?.price || 0);
    navigate("/packages/buy");
  };

  const handleCarSelection = (packageId: string, carId: string) => {
    setSelectedCars((prev) => ({ ...prev, [packageId]: carId }));
  };

  if (isLoadingPackages || isLoadingCars) {
    return <Loading />;
  }

  if (!packages || !cars) {
    return <div className="text-center">لا توجد بيانات متاحة</div>;
  }

  return (
    <div className="container">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold text-primary">الباقات</h1>
      </div>
      <div className="mt-8 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
        {packages.map((pkg) => (
          <div key={pkg.id} className="rounded-lg bg-white p-6 shadow-md">
            <div className="flex items-center gap-4">
              <Icon name="package" className="text-primary" size={40} />
              <Separator orientation="vertical" className="h-12" />
              <div>
                <h2 className="text-lg font-bold text-primary">{pkg.name}</h2>
                <p className="text-sm text-gray-600">{pkg.description}</p>
              </div>
            </div>
            <Separator className="my-4" />
            <div className="flex justify-between">
              <p className="text-lg font-bold text-primary">{pkg.price} ريال</p>
              <p className="text-sm text-gray-500">
                صلاحية الباقة: {pkg.expiryDays} يوم
              </p>
            </div>
            {pkg.oneCar && (
              <div className="mt-4">
                <label
                  htmlFor={`carSelect-${pkg.id}`}
                  className="mb-2 block text-sm font-medium"
                >
                  اختر السيارة:
                </label>
                <div className="relative">
                  <select
                    id={`carSelect-${pkg.id}`}
                    className="block w-full appearance-none rounded-md border border-gray-300 bg-white p-2 pr-8 text-sm"
                    onChange={(e) => handleCarSelection(pkg.id, e.target.value)}
                    value={selectedCars[pkg.id] || ""}
                  >
                    <option value="">اختر سيارة</option>
                    {cars.map((car) => (
                      <option key={car.id} value={car.id}>
                        {car.manufacture} {car.model} -{" "}
                        {car.plateNumber || "بدون لوحة"}
                      </option>
                    ))}
                  </select>
                  <ChevronDown
                    className="absolute left-2 top-1/2 -translate-y-1/2 transform text-gray-400"
                    size={16}
                  />
                </div>
              </div>
            )}
            <Button
              onClick={() => handlePackageSelection(pkg.id, pkg.oneCar)}
              className="mt-4 w-full bg-primary text-white"
              disabled={pkg.oneCar && !selectedCars[pkg.id]}
            >
              {pkg.oneCar && !selectedCars[pkg.id]
                ? "اختر سيارة أولاً"
                : "اشترِ الآن"}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Packages;
