import { handleZodError } from "@/helpers/zod-error-handler";
import axiosInstance from "../lib/axios";
import {
  ICreateBookingByPackageDto,
  IMyPackagePurchase,
  IPackages,
} from "@/types/types";

export const getAllPackages = async () => {
  try {
    const response = await axiosInstance.get<IPackages[]>("/package");

    return response.data;
  } catch (error) {
    handleZodError(error);
  }
};

export const getMyPackages = async () => {
  try {
    const response =
      await axiosInstance.get<IMyPackagePurchase[]>("/package/purchased");
    console.log(response.data);
    return response.data;
    return response.data;
  } catch (error) {
    handleZodError(error);
  }
};

export const bookByPackage = async (data: ICreateBookingByPackageDto) => {
  try {
    const response = await axiosInstance.post("/booking/by-package", data);

    return response.data;
  } catch (error) {
    handleZodError(error);
  }
};
