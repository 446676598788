export interface IZodError {
  code: string;
  errors: {
    path: string;
    message: string;
  }[];
}

export interface IService {
  id: string;
  name: string;
  icon: string | undefined;
  description: string | null;
  price: number;
  duration: number;
}

export interface ICars {
  id: string;
  manufacture: string;
  model: string;
  plateNumber: string | null;
  color: string;
}

export type IEditCar = Partial<ICars> & { id: string };

export interface INewCar {
  manufacture?: string;
  model?: string;
  plateNumber?: string;
  color?: string;
}

export interface ITime {
  date: Date;
  serviceId: string;
}

export enum BookingStatus {
  Initiated = "Initiated",
  Scheduled = "Scheduled",
  New = "New",
  InProgress = "InProgress",
  Completed = "Completed",
  Cancelled = "Cancelled",
}

export interface IBooking {
  id: string;
  startTime: string;
  totalAmount: number;
  status: BookingStatus;
  service: {
    name: string;
    duration: number;
  };
  car: {
    manufacture: string;
    model: string;
  };
  location: {
    id: string;
    title: string;
  };
}
export interface IBook {
  paymentId: string;
}

export interface ILocation {
  id: string;
  title: string;
  lat: number;
  lng: number;
  address: string;
  isDeleted: boolean;
  userId: string;
  createdAt: Date | null;
  updatedAt: Date | null;
}
[];

export interface ICreateBookingByPackageDto {
  bookingDate: Date;
  time?: string;
  packageId: string;
  serviceId: string;
  carId?: string;
  locationId: string;
}

export interface IPackages {
  id: string;
  name: string;
  description: string | null;
  price: number;
  expiryDays: number;
  oneCar: boolean;
  isDeleted: boolean;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
  items: {
    id: string;
    packageId: string;
    serviceId: string;
    quantity: number;
  }[];
}
[];

export interface INewLocation {
  title: string;
  address: string;
  lng: number;
  lat: number;
}

export interface ICoupon {
  id: string;
  code: string;
  discount_amount: number;
  max_discount_percentage: number | null;
  isFixed: boolean;
}

export interface ICouponCheck {
  code: string;
  serviceId: string;
}

export interface Source {
  type: string;
  company: string;
  name: string;
  number: string;
  gateway_id: string;
  reference_number: string;
  token: string;
  message: string;
  transaction_url: string;
  response_code: string;
  authorization_code: string;
  issuer_name: string;
  issuer_country: string;
  issuer_card_type: string;
  issuer_card_category: string;
}

interface MoyasarConfig {
  element: string;
  amount: number;
  currency: string;
  description: string;
  publishable_api_key: string;
  callback_url: string;
  metadata?: Record<string, unknown>;
  apple_pay?: {
    version?: number;
    country: string | string[];
    merchant_capabilities?: string[];
    label: string;
    validate_merchant_url: string;
  };
  methods: string[];
  on_completed?: ((payment: IPayment) => unknown) | undefined;
}

interface Moyasar {
  init(config: MoyasarConfig): void;
}

export interface Window {
  Moyasar?: Moyasar;
}

enum PaymentMethod {
  creditcard = "creditcard",
  applepay = "applepay",
  stcpay = "stcpay",
}

enum PaymentStatus {
  initiated = "initiated",
  paid = "paid",
  authorized = "authorized",
  captured = "captured",
  refunded = "refunded",
  voided = "voided",
  verified = "verified",
}

export interface IPayment {
  id: string;
  status: PaymentStatus;
  amount: number;
  fee: number;
  amount_format?: string;
  currency?: string;
  refunded?: number;
  refunded_at?: string;
  captured: number;
  captured_at?: string;
  voided_at?: string;
  description?: string;
  invoice_id?: string;
  ip: string;
  callback_url?: string;
  metadata?: string;
  source: ISource;
  updated_at: string;
  created_at: string;
  captured_format?: string;
  refunded_format?: string;
  fee_format?: string;
}

export interface ISource {
  type: PaymentMethod;
  mobile?: string;
  branch?: string;
  cashier?: string;
  company?: string;
  name?: string;
  number?: string;
  month?: number;
  year?: number;
  dpan?: string;
  gateway_id?: string;
  reference_number?: string;
  token?: string;
  message?: string;
  transaction_url?: string;
  response_code?: string;
  authorization_code?: string;
  issuer_name?: string;
  issuer_country?: string;
  issuer_card_type?: string;
  issuer_card_category?: string;
}

export interface ICreateBooking {
  servicePrice: number;
  serviceId: string;
  bookingDate: Date;
  startTime: string;
  carId: string;
  couponCode?: string;
  locationId: string;
}

// Interface for the package item
export interface IMyPackageItem {
  id: string;
  packageId: string;
  serviceId: string;
  quantity: number;
}

// Main package interface
export interface IMyPackage {
  id: string;
  name: string;
  description: string;
  price: number;
  expiryDays: number;
  oneCar: boolean;
  isDeleted: boolean;
  isActive: boolean;
  createdAt: string; // ISO 8601 date string
  updatedAt: string; // ISO 8601 date string
  items: IMyPackageItem[];
}

interface Service {
  id: string;
  name: string;
  description: string;
  isDeleted: boolean;
  icon: string;
  duration: number;
  price: number;
}

interface ServiceUsage {
  service: Service;
  totalQuantity: number;
  usedQuantity: number;
  serviceId: string;
}

interface Package {
  id: string;
  name: string;
  description: string;
  price: number;
  expiryDays: number;
  oneCar: boolean;
  isDeleted: boolean;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
}

interface Payment {
  id: string;
  paymentID: string;
  status: string;
  amount: number;
  fee: number;
  amount_format: string;
  currency: string;
  refunded: number;
  refunded_at: string | null;
  captured: number;
  captured_at: string | null;
  voided_at: string | null;
  description: string;
  invoice_id: string | null;
  ip: string | null;
  callback_url: string | null;
  metadata: {
    carId?: string;
    token?: string;
    packageId?: string;
  } | null;
  bookingId: string | null;
  createdAt: string;
  updatedAt: string;
}

interface User {
  id: string;
  name: string;
  phone: string;
  createdAt: string;
  updatedAt: string;
  isActive: boolean;
}

export interface IMyPackagePurchase {
  id: string;
  package: Package;
  expiresAt: string;
  user: User;
  payment: Payment;
  ServiceUsage: ServiceUsage[];
  carId?: string | null | undefined;
}
