import { useFormContext } from "react-hook-form";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

import { z } from "zod";
import { carSchema } from "./EditCar";
import { Input } from "@/components/ui/input";

const PlateCar = () => {
  const form = useFormContext<z.infer<typeof carSchema>>();
  return (
    <FormField
      control={form.control}
      name="plateNumber"
      render={({ field }) => (
        <FormItem>
          <FormLabel aria-required>اللوحة</FormLabel>
          <FormControl>
            <Input dir="rtl" {...field} placeholder={field.value} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default PlateCar;
