"use client";

import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { z } from "zod";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../ui/form";
import { Popover, PopoverContent, PopoverTrigger } from "../../ui/popover";
import { Button } from "../../ui/button";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { Calendar } from "../../ui/calendar";
import useCartStore from "@/store/useCartStore";
import { packageBookSchema } from "@/pages/bookings/bookByPackage";
const DateComp = () => {
  const form = useFormContext<z.infer<typeof packageBookSchema>>();
  const [calendarOpen, setCalendarOpen] = useState(false);
  const { setDate } = useCartStore((state) => state);
  return (
    <FormField
      control={form.control}
      name="bookingDate"
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <FormLabel>تاريخ الحجز</FormLabel>
          <Popover
            open={calendarOpen}
            onOpenChange={(open) => setCalendarOpen(open)}
          >
            <PopoverTrigger asChild>
              <FormControl dir="ltr">
                <Button
                  variant={"outline"}
                  className={cn(
                    "w-full pl-3 text-left font-normal",
                    !field.value && "text-muted-foreground",
                  )}
                >
                  <CalendarIcon className="mr-auto h-4 w-4 opacity-50" />
                  {field.value ? (
                    format(field.value, "PPP")
                  ) : (
                    <span>اختر التاريخ</span>
                  )}
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="end">
              <Calendar
                mode="single"
                selected={field.value}
                dir="ltr"
                onSelect={(e) => {
                  field.onChange(e);
                  setDate(e);
                  setCalendarOpen(false);
                }}
                disabled={(date) => {
                  const today = new Date();
                  today.setHours(0, 0, 0, 0); // Set time to start of day
                  const dayAfterSevenDays = new Date(today);
                  dayAfterSevenDays.setDate(today.getDate() + 7);
                  return date < today || date > dayAfterSevenDays;
                }}
              />
            </PopoverContent>
          </Popover>

          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default DateComp;
