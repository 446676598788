"use client";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { ICars, IEditCar } from "@/types/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../../ui/button";
import Icon from "../../ui/icon";
import ManufactureCar from "./ManufactureCar";
import ModeleCar from "./ModelCar";
import ColorCar from "./ColorCar";
import PlateCar from "./PlateCar";
import { useMutation } from "@tanstack/react-query";
import { editCar } from "@/api/car";
import { toast } from "@/components/ui/use-toast";
import React from "react";
import { queryClient } from "@/lib/react-query";

export const carSchema = z.object({
  manufacture: z.string(),
  model: z.string(),
  color: z.string(),
  plateNumber: z.string(),
});

const EditCarForm = ({ car }: { car: ICars }) => {
  const [open, setOpen] = React.useState(false);
  const { mutate, isPending } = useMutation({
    mutationKey: ["cars", car.id],
    mutationFn: (data: IEditCar) => editCar(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["cars"] });
      toast({
        title: "تم تعديل السيارة بنجاح",
      });
      setOpen(false);
    },
    onError: (error) => {
      toast({
        title: "حدث خطأ ما",
        description: error.message,
        variant: "destructive",
      });
    },
  });

  const form = useForm<z.infer<typeof carSchema>>({
    resolver: zodResolver(carSchema),
    defaultValues: {
      manufacture: car.manufacture,
      model: car.model,
      color: car.color,
      plateNumber: car.plateNumber || "",
    },
  });

  // 2. Define a submit handler.
  function onSubmit(values: z.infer<typeof carSchema>) {
    const data = { ...values, id: car.id };
    mutate(data);
  }
  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <Button size="icon">
          <Icon name="pencil" size={20} />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogDescription asChild className="container pt-4 text-right">
            <FormProvider {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-8 text-right"
              >
                <DialogTitle className="text-center">تعديل السيارة</DialogTitle>
                <ManufactureCar />
                <ModeleCar />
                <ColorCar />
                <PlateCar />
                <Button isLoading={isPending} type="submit">
                  حفظ
                </Button>
              </form>
            </FormProvider>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default EditCarForm;
