import { handleZodError } from "@/helpers/zod-error-handler";
import axiosInstance from "../lib/axios";
import { ILocation, INewLocation } from "@/types/types";
import axios from "axios";

export const getAllLocations = async () => {
  try {
    const response = await axiosInstance.get<ILocation[]>("/location");

    return response.data;
  } catch (error) {
    handleZodError(error);
  }
};

export const addNewLocation = async (data: INewLocation) => {
  try {
    const response = await axiosInstance.post("/location", data);

    return response.data;
  } catch (error) {
    handleZodError(error);
  }
};

export const deleteLocation = async (id: string) => {
  try {
    const response = await axiosInstance.delete(`/location/${id}`);

    return response.data;
  } catch (error) {
    handleZodError(error);
  }
};

const GOOGLE_TOKEN = "AIzaSyD2lT6cRgEgijt6nOS6eJUB4ErmBZ7sczw";
export const getGoogleMapAddress = (lat: number, lng: number) => {
  return axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_TOKEN}`,
  );
};
