import LocationForm from "@/components/Location/new/Form";

const NewLocation = () => {
  return (
    <div>
      {/* one component to make it reusable later */}
      <LocationForm />
    </div>
  );
};

export default NewLocation;
