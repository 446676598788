export const QUESTIONS = [
  {
    title: "كيف كيف يمكنني حجز موعد؟",
    description:
      "يمكنك الحجز عن طريق -احجز- واختيار السيارة المناسبة لك وملئ البيانات الخاصة بك ومن ثم تأكيد الحجز",
  },
  {
    title: "هل يمكنني إلغاء موعد الغسيل؟",
    description:
      "نعم يمكنك إلغاء الحجز ولكن يجب عليك الإلغاء قبل 24 ساعة من موعد الحجز",
  },
  {
    title: "هل يمكنني تغيير الموعد الغسيل؟",
    description:
      "نعم يمكنك تغيير موعد الحجز ولكن يجب عليك التواصل مع الدعم الفني لتغيير الموعد",
  },
  {
    title: "ماذا ان لم اكن راضي عن الخدمة المقدمة ؟",
    description:
      "في حال عدم رضائك عن الخدمة يجب التواصل معنا مباشرة بما لا يتجاوز ساعة بعد الانتهاء من تقديم الخدمة بشكل كامل على الرقم الموحد 0570505066 وسيتم تصحيح الخلل",
  },
  {
    title: "ماذا لو تم الغاء الطلب من قبل العميل قبل موعد الحجز قبل 4 ساعات؟",
    description: "يتم الغاء الحجز بدون استرجاع للقيمة المدفوعة",
  },
  {
    title: "ماهي طرق الدفع المقبولة ؟",
    description: "الدفع الالكتروني  / نقدا / الدفع بالبطاقة لدى موقع العميل",
  },
  {
    title: "ماهي طريقة الغسيل؟",
    description: `تنظيف داخلي: 
بإزالة الأتربة وتنظيفها باستخدام مواد تنظيف آمنة ومخصصة للقماش واستخدام أدوات تنظيف فريدة لكل عميل.
غسيل خارجي: 
باستخدام مادة خاصة وآمنة لإزالة الأوساخ والغبار وإعادة البريق واللمعان للسيارة من الخارج.
تعطير السيارة: 
إضافة لمسة نهائية بواسطة معطرات سويتر الخاصة لمنح سيارتك رائحة مميزة ومنعشة.
هدية مجانية: 
إضافة كيس نفايات لكل عميل بعد كل غسلة.`,
  },
  {
    title: "ماهي المدن المغطاة ؟",
    description: "حاليا مدينة الرياض فقط \nوجاري العمل لاضافة المدن الاخرى",
  },
  {
    title: "كم تستغرق خدمة الغسيل؟",
    description:
      "تستغرق تقديم الخدمة 45 دقيقة\n*قد تزيد مدة الغسيل حسب حالة السيارة.",
  },
  {
    title: "هل بإمكان العميل تقديم أو تأجيل أو الغاء الموعد؟",
    description:
      "يمكن للعميل تقديم أو تأجيل او الغاء الموعد من خلال التواصل مع خدمة العملاء",
  },
  {
    title: "في حالة إلغاء الموعد من قبل العميل هل يوجد رسوم؟",
    description:
      "إذا تم إلغاء الطلب خلال 4 ساعات  أو أقل قبل الموعد أو في حال عدم رد العميل على اتصال الموظف بعد وصوله للموقع او تاخيره بالحضور للموقع سيتم احتساب مبلغ 100% من قيمة الفاتورة من العميل ( وتعتبر الخدمة مقدمة )",
  },
  {
    title: "هل يمكن للعميل إضافة سيارة أخرى بعد وصول البايكر؟",
    description: "يرجى التواصل مع فريق خدمة العملاء لخدمتك.",
  },
  {
    title: "ماهي طرق الدفع؟",
    description: "الدفع الإلكتروني (مدى، فيزا، ماستر كارد، ابل باي)",
  },
  {
    title:
      "في حال وصول الموظف للموقع هل يستطيع البدء بالتنظيف حتى ولم يرد العميل على الإتصال ؟",
    description:
      "نعم، في حال كانت معلومات السيارة واضحة ومتاحة في موقع واضح ومساحة كافية للعمل.",
  },
  {
    title: "هل أستطيع الحجز لأكثر من سيارة؟",
    description: "نعم، لكل سيارة حجزها بموعدها الخاص.",
  },
  {
    title: "هل يحضر الموظف دائماً على نفس الموعد ؟",
    description:
      "نعم، نحرص جاهدين الوصول في الموعد المحدد.وفي بعض الحالات قد يصل (قبل/بعد) الموعد بربع ساعة، في حال وصوله قبل الموعد فإنه ينتظر في الموقع حتى يحين موعد العميل.",
  },
  {
    title: "في حال وجود حوض للسيارة هل يتم غسيل الحوض (بيك اب ) ؟",
    description: "لا",
  },
  {
    title: "هل يتم تنظيف شنطة سيارة سيدان ؟",
    description:
      "حاليًا تشمل التنظيف السطحي وليس العميق، الأدراج وشنطة السيارة ليست من ضمن خيارات التنظيف لدينا.",
  },
  {
    title: "كم مدة إنتظار الموظف بالموقع ؟",
    description:
      "يمنح العميل 15 دقيقة إنتظار في موقعه وفي حال عدم الرد يتم إلغاء الموعد ( وتعتبر الخدمة مقدمة ).",
  },
];
