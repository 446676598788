import { useFormContext } from "react-hook-form";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

import { z } from "zod";
import { carSchema } from "./EditCar";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { cn } from "@/lib/utils";

const COLORS = [
  { label: "أسود", value: "black" },
  { label: "أبيض", value: "white" },
  { label: "أزرق", value: "blue" },
  { label: "أحمر", value: "red" },
  { label: "بني", value: "brown" },
  { label: "أصفر", value: "yellow" },
  { label: "ذهبي", value: "gold" },
  { label: "برتقالي", value: "orange" },
  { label: "فضي", value: "silver" },
] as const;

const ColorCar = () => {
  const form = useFormContext<z.infer<typeof carSchema>>();
  return (
    <FormField
      control={form.control}
      name="color"
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <FormLabel>
            <span>اللون</span>
          </FormLabel>
          <FormItem className="space-y-3">
            <FormControl>
              <RadioGroup
                onValueChange={field.onChange}
                defaultValue={field.value}
                dir="rtl"
                className="flex flex-wrap space-y-1"
              >
                {COLORS.map((color) => (
                  <FormItem
                    key={color.value}
                    className="flex items-center space-x-3 space-y-0"
                  >
                    <FormControl>
                      <RadioGroupItem className="hidden" value={color.value} />
                    </FormControl>
                    <FormLabel
                      style={{ backgroundColor: color.value }}
                      className={cn(
                        "flex size-6 cursor-pointer items-center justify-center rounded-md  border border-gray-300 ",
                        field.value === color.value
                          ? "border-4 border-primary"
                          : "border-gray-300",
                      )}
                    ></FormLabel>
                  </FormItem>
                ))}
              </RadioGroup>
            </FormControl>
            <FormMessage />
          </FormItem>

          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default ColorCar;
