import { Popover, PopoverContent, PopoverTrigger } from "../../ui/popover";
import { cn } from "@/lib/utils";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../ui/command";
import { Check, ChevronsUpDown } from "lucide-react";
import React from "react";
import { ScrollArea } from "../../ui/scroll-area";
import { useFormContext } from "react-hook-form";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

import { Button } from "@/components/ui/button";
import { z } from "zod";
import { carSchema } from "./EditCar";
import { CARS } from "@/constant/car-models";

const ModeleCar = () => {
  const [open, setOpen] = React.useState(false);
  const form = useFormContext<z.infer<typeof carSchema>>();
  return (
    <FormField
      control={form.control}
      name="model"
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <FormLabel>
            <span>نوع السيارة</span>
          </FormLabel>
          <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger dir="rtl" asChild>
              <FormControl>
                <Button
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "w-full justify-between",
                    !field.value && "text-muted-foreground",
                  )}
                >
                  {field.value ? field.value : "اختر نوع السيارة"}
                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent dir="rtl" align="start" className="w-full p-0">
              <Command dir="rtl">
                <CommandInput placeholder="ابحث عن الشركة..." />
                <CommandEmpty>لم يتم العثور على نتائج</CommandEmpty>
                <CommandGroup>
                  <CommandList>
                    <ScrollArea
                      dir="rtl"
                      className="h-72 w-48 rounded-md border"
                    >
                      {CARS.filter(
                        (car) => car.brand === form.watch("manufacture"),
                      ).flatMap((car) =>
                        car.models.map((model, index) => (
                          <CommandItem
                            value={model}
                            key={index}
                            onSelect={() => {
                              form.setValue("model", model);
                              setOpen(false);
                            }}
                          >
                            <Check
                              className={cn(
                                "mr-2 h-4 w-4",
                                model === form.watch("model")
                                  ? "opacity-100"
                                  : "opacity-0",
                              )}
                            />
                            {model}
                          </CommandItem>
                        )),
                      )}
                    </ScrollArea>
                  </CommandList>
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default ModeleCar;
