import { Popover, PopoverContent, PopoverTrigger } from "../../ui/popover";
import { cn } from "@/lib/utils";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../ui/command";
import { Check, ChevronsUpDown } from "lucide-react";
import React from "react";
import { ScrollArea } from "../../ui/scroll-area";
import { useFormContext } from "react-hook-form";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { carSchema } from "./EditCar";
import { z } from "zod";
import { CARS } from "@/constant/car-models";

const ManufactureCar = () => {
  const [open, setOpen] = React.useState(false);
  const form = useFormContext<z.infer<typeof carSchema>>();
  return (
    <FormField
      control={form.control}
      name="manufacture"
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <FormLabel>
            <span>الشركة المصنعة</span>
          </FormLabel>
          <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger dir="rtl" asChild>
              <FormControl>
                <Button
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "w-full justify-between",
                    !field.value && "text-muted-foreground",
                  )}
                >
                  {field.value || "اختر الشركة المصنعة"}
                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent dir="rtl" align="start" className="w-full p-0">
              <Command dir="rtl">
                <CommandInput placeholder="ابحث عن الشركة..." />
                <CommandEmpty>لم يتم العثور على نتائج</CommandEmpty>
                <CommandGroup>
                  <CommandList>
                    <ScrollArea
                      dir="rtl"
                      className="h-72 w-48 rounded-md border"
                    >
                      {CARS.map((manufacture, index) => (
                        <CommandItem
                          value={manufacture.brand}
                          key={index}
                          onSelect={() => {
                            form.setValue("manufacture", manufacture.brand);
                            setOpen(false);
                          }}
                        >
                          <Check
                            className={cn(
                              "mr-2 h-4 w-4",
                              manufacture.brand === field.value
                                ? "opacity-100"
                                : "opacity-0",
                            )}
                          />
                          {manufacture.brand}
                        </CommandItem>
                      ))}
                    </ScrollArea>
                  </CommandList>
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default ManufactureCar;
