import { create } from "zustand";

interface CartState {
  serviceId: string | null;
  carId: string | null;
  locationId: string | null;
  date: Date | undefined;
  servicePrice: number | null;
  time: string | null;
  coupon?: string | null;
  totalAfterDiscount: number;
  setTotalAfterDiscount: (totalAfterDiscount: number) => void;
  setCoupon: (coupon: string | null) => void;
  setServicePrice: (price: number | null) => void;
  setServiceId: (serviceId: string | null) => void;
  setDate: (date: Date | undefined) => void;
  setCardId: (cardId: string | null) => void;
  setLocationId: (locationId: string | null) => void;
  setTime: (time: string | null) => void;
  emptyCart: () => void;
}

const useCartStore = create<CartState>((set) => ({
  serviceId: null,
  servicePrice: null,
  carId: null,
  locationId: null,
  date: undefined,
  totalAfterDiscount: 0,
  coupon: null,
  time: null,
  setTotalAfterDiscount: (totalAfterDiscount) => set({ totalAfterDiscount }),
  setCoupon: (coupon) => set({ coupon }),
  setServicePrice: (servicePrice) => set({ servicePrice }),
  setServiceId: (serviceId) => set({ serviceId }),
  setDate: (date) => set({ date }),
  setCardId: (carId) => set({ carId }),
  setLocationId: (locationId) => set({ locationId }),
  setTime: (time) => set({ time }),
  emptyCart: () =>
    set({
      serviceId: null,
      servicePrice: null,
      carId: null,
      locationId: null,
      date: undefined,
      coupon: null,
    }),
}));

export default useCartStore;
