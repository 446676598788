"use client";

import { bookSchema } from "@/pages/bookings/book";
import { useFormContext } from "react-hook-form";
import { z } from "zod";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../ui/form";
import { useQuery } from "@tanstack/react-query";
import { getAllLocations } from "@/api/location";
import { useEffect, useState } from "react";
import { ILocation } from "@/types/types";
import { Input } from "@/components/ui/input";
import useCartStore from "@/store/useCartStore";

const Location = () => {
  const form = useFormContext<z.infer<typeof bookSchema>>();
  const { setLocationId } = useCartStore((state) => state);
  const [chosenLocation, setChosenLocation] = useState<ILocation | undefined>(
    undefined,
  );
  const { data, isLoading } = useQuery({
    queryKey: ["locations"],
    queryFn: getAllLocations,
  });

  useEffect(() => {
    if (form.watch("locationId")) {
      const location = data?.find((loc) => loc.id === form.watch("locationId"));
      setChosenLocation(location);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.watch("locationId"), data, form]);

  return (
    <>
      <FormField
        control={form.control}
        name="locationId"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="flex items-center justify-between">
              الموقع
            </FormLabel>
            <FormControl>
              <select
                dir="rtl"
                disabled={isLoading}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  setLocationId(e.target.value);
                }}
                value={field.value}
                className="h-12 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
              >
                <option value="">
                  {isLoading ? "جاري التحميل" : "اختر موقع"}
                </option>
                {data?.map((location, index) => (
                  <option key={index} value={location.id}>
                    {location.title}
                  </option>
                ))}
              </select>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      {chosenLocation && (
        <div className="mt-4">
          <Input value={chosenLocation.address} disabled name="address" />
        </div>
      )}
    </>
  );
};

export default Location;
