import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { getAllTimesByService } from "@/api/times";
import { ITime } from "@/types/types";
import useCartStore from "@/store/useCartStore";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../ui/form";
import { RadioGroup, RadioGroupItem } from "../../ui/radio-group";
import Icon from "@/components/ui/icon";
import { cn } from "@/lib/utils";
import { packageBookSchema } from "@/pages/bookings/bookByPackage";

const TimeComp: React.FC = () => {
  const { setTime } = useCartStore((state) => state);
  const { getValues, control, watch, setValue } =
    useFormContext<z.infer<typeof packageBookSchema>>();

  const {
    isLoading,
    data: TIMES,
    isFetched,
    refetch,
  } = useQuery({
    queryKey: ["times", getValues("serviceId"), getValues("bookingDate")],
    queryFn: ({ queryKey }) => {
      const serviceId = queryKey[1] as string;
      const dateString = queryKey[2] as string;
      const date = new Date(dateString);

      return getAllTimesByService({
        serviceId,
        date,
      } as ITime);
    },
    enabled: !!getValues("serviceId") && !!getValues("bookingDate"),
  });

  useEffect(() => {
    if (watch("serviceId") && watch("bookingDate")) {
      setValue("time", "");
      setTime("");
      refetch();
    }
  }, [watch("serviceId"), watch("bookingDate"), setValue, setTime, refetch]);

  useEffect(() => {
    setTime(getValues("time"));
  }, [watch("time"), getValues, setTime]);

  const convertUTCToLocal = (utcTime: string) => {
    const [hours, minutes] = utcTime.split(":").map(Number);
    const date = new Date();
    date.setUTCHours(hours, minutes, 0, 0);
    return date;
  };

  const formatTime = (utcTime: string) => {
    const localDate = convertUTCToLocal(utcTime);
    return localDate.toLocaleTimeString("ar-SA", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
  };

  if (!getValues("serviceId") || !getValues("bookingDate")) {
    return (
      <div>
        <FormLabel>الوقت</FormLabel>
        <p className="p-4 text-center font-bold">
          اختر الخدمة والتاريخ لاختيار الوقت
        </p>
      </div>
    );
  }

  if (!TIMES?.length && isFetched) {
    return (
      <div>
        <p className="p-4 text-center font-bold">لا يوجد أوقات متاحة</p>
      </div>
    );
  }

  return (
    <FormField
      control={control}
      name="time"
      render={({ field }) => (
        <FormItem className="spa snap-end space-y-3">
          <FormLabel className="static">اختر الوقت</FormLabel>
          <div className="overflow-auto">
            <FormControl>
              <RadioGroup
                onValueChange={field.onChange}
                defaultValue={field.value}
                className="flex w-fit"
              >
                {isLoading ? (
                  <div className="flex items-center justify-center">
                    <Icon
                      name="loader"
                      className="h-5 w-5 animate-spin text-primary"
                    />
                  </div>
                ) : (
                  TIMES?.map((time, index) => {
                    const formattedTime = formatTime(time);
                    const [timeValue, period] = formattedTime.split(" ");
                    return (
                      <FormItem
                        key={index}
                        className={cn(
                          "mb-4 flex size-16 items-center justify-center rounded-md border",
                          field.value === time &&
                            "bg-primary text-white transition-all duration-300",
                        )}
                      >
                        <FormControl>
                          <RadioGroupItem value={time} className="hidden" />
                        </FormControl>
                        <FormLabel className="flex size-16 cursor-pointer flex-col items-center justify-center gap-2 rounded-md font-bold">
                          {timeValue}
                          <span>{period}</span>
                        </FormLabel>
                      </FormItem>
                    );
                  }).reverse()
                )}
              </RadioGroup>
            </FormControl>
          </div>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default TimeComp;
