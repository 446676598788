import { create } from "zustand";

interface PackageState {
  carId?: string | null;
  packageId: string | null;
  packagePrice: number;
  setCarId: (carId: string | null) => void;
  setPackageId: (packageId: string | null) => void;
  setPackagePrice: (packagePrice: number) => void;
}

const usePackageStore = create<PackageState>((set) => ({
  carId: null,
  packageId: null,
  packagePrice: 0,
  setCarId: (carId) => set({ carId }),
  setPackageId: (packageId) => set({ packageId }),
  setPackagePrice: (packagePrice) => set({ packagePrice }),
}));

export default usePackageStore;
