import { QUESTIONS } from "@/constant/faq";
import { FaWhatsapp } from "react-icons/fa";

export const Help = () => {
  return (
    <div className="container">
      <div className="rounded-lg border p-4 shadow-md">
        <h1 className="text-2xl  font-bold text-primary">الدعم الفني</h1>
        <div className="flex flex-col gap-4">
          <h2 className="text-xl font-bold text-primary">الأسئلة الشائعة</h2>
          <div className="flex flex-col gap-4">
            {QUESTIONS.map((question, index) => (
              <div className="flex flex-col gap-2" key={index}>
                <h3 className="text-lg font-bold text-primary">
                  {index + 1}. {question.title}
                </h3>
                <p>{question.description}</p>
              </div>
            ))}
          </div>
          <div className="text-font-extrabold font-bold text-green-600">
            <h4>
              إذا كان لديك سؤال آخر لا تتردد بالتواصل معنا عبر الواتساب عبر
              الواتساب
            </h4>
            <div>
              <a
                href="https://api.whatsapp.com/send?phone=966570505066"
                target="_blank"
                className=" flex items-center gap-2"
              >
                966570505066
                <FaWhatsapp size={24} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
